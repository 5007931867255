html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  align-items: center;
  /* padding: 1em; */
  overflow: hidden;
  background: rgb(0, 0, 32);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.burnbox-outer {
  /* height: 100%; */
  flex-basis: 100%;
  flex-grow: 10;
  /* flex-shrink: 1; */
  padding: 1em;
  overflow: visible;
  z-index: 1000;
  /* position: relative; */
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.burnbox {
  resize: none;
  font-size: 2em;
  /* padding: 2em; */
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 50px 0 white;
  transition: all ease 2s;
  flex-basis: 100%;
  flex-grow: 1;
  padding: 1em;
  border-width: 1px;
  border-style: solid;
  border-color: white;
}

.burnbox.burning {
  background: tan;
  width: 70%;
  box-shadow: 0 0 100px 0 tan;
  border: none;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  .burnbox {
    color: white;
    background: black;
    box-shadow: 0 0 50px 0 #dddddd;
    border-color: #dddddd;
  }

  .burnbox.burning {
    background: #444444;
  }
}

.fire {
  background: url("./fire.gif"), linear-gradient(#00000000, red);
  background-size: auto 5em;
  background-repeat: repeat-x;
  height: 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  margin-top: -1em;
  padding: 0;
  z-index: 2000;
  transform: rotate(180deg);
  transition: all ease 1s;
}

.fire.burning {
  height: 5em;
}

.burnbutton {
  flex-basis: 2em;
  /* flex-grow: 1; */
  font-size: 2em;
  width: 70%;
  z-index: 0;
  background: red;
  border: 2px solid darkred;
  box-shadow: 0 0 50px 0 red;
  color: white;
  margin: 1em;
}
